var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    label: "회의기간",
                    defaultStart: "-3M",
                    defaultEnd: "0",
                    name: "period",
                  },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-tab", {
        attrs: {
          type: "tabcard",
          align: "left",
          inlineLabel: true,
          tabItems: _vm.tabItems,
        },
        on: { tabClick: _vm.tabClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    searchParam: _vm.searchParam,
                    tabParam: _vm.tabParam,
                    proceedingsTypeCd: tab.proceedingsTypeCd,
                  },
                  on: {
                    "update:searchParam": function ($event) {
                      _vm.searchParam = $event
                    },
                    "update:search-param": function ($event) {
                      _vm.searchParam = $event
                    },
                    "update:tabParam": function ($event) {
                      _vm.tabParam = $event
                    },
                    "update:tab-param": function ($event) {
                      _vm.tabParam = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }